import React from 'react';
import dittoIntro from '../assets/ditto_intro.png'
import internActivate from '../assets/internmode_activate.png'
import interOption from '../assets/internmode_1.png'
import internRoast from '../assets/internroast.png'
import xPostResponse from '../assets/xresponse.png'

import actionHistoryImg from '../assets/history.png';
import addressBookImg from '../assets/contacts.png';
import preferencesImg from '../assets/settings.png';
import sendImg from '../assets/send_2.png';
import assetsImg from '../assets/asset_view.png';

import {LightTheme} from '../styles/colors';
import appStoreBadge from '../assets/Download_on_the_App_Store_Badge.svg';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const Product: React.FC = () => {
  const features = [
    {
      title: "One command for anything 🪄",
      description: "Just tell Ditto what you want to do. Remember your frens once, and we'll handle all wallet actions - transfers, swaps, bridges, and more - automatically.",
      platforms: ["ios", "web"],
      images: [sendImg]
    },
    {
      title: "Intern mode 🔥",
      description: "Your degen intern who loves memes, manages your crypto with style, and helps you craft viral X posts 🚀 wagmi 🫡",
      platforms: ["ios", "web"],
      images: [internRoast]
    },
    {
      title: "Know what's happening 👀",
      description: "See all your actions in simple terms. No more staring at confusing transaction hashes and logs.",
      platforms: ["ios", "web"],
      images: [actionHistoryImg]
    },
    // {
    //   title: "Your wallet, your way ⚡",
    //   description: "Set your preferences once, and Ditto will remember exactly how you like to move your bags.",
    //   platforms: ["ios", "web"],
    //   images: [preferencesImg]
    // },
    {
      title: "Your crypto, your key 🔐",
      description: "Full control of your assets with self-custody. Stay safu, no middlemen.",
      platforms: ["ios", "web"],
      images: [assetsImg]
    },
  ];

  return (
    <div style={styles.product}>
      <div style={styles.heroSection}>
        <div style={styles.leftColumn}>
          <p style={styles.heroTitle}>
            Crypto as easy as texting
          </p>
          <p style={styles.heroSubtitle}>
            Meet Ditto, your personal crypto-native assistant who speaks your language. 
            <br />
            From memes to millions, Ditto will handle your wallet with style and security.
          </p>
          <div style={styles.ctaButtons}>
            <a 
              href="https://apps.apple.com/us/app/ditto-ai-wallet/id6736664696" 
              target="_blank" 
              rel="noopener noreferrer"
              style={styles.appStoreLink}
            >
              <img 
                src={appStoreBadge} 
                alt="Download on the App Store" 
                style={styles.appStoreBadge}
              />
            </a>
            {/* <span style={styles.orText}>OR</span>
            <Link 
              to="/chat" 
              style={styles.webPreviewButton}
            >
              Launch Web Preview
            </Link> */}
          </div>
        </div>
        <div style={styles.rightColumn}>
          <div style={styles.imageStack}>
            {/* <img src={interOption} alt="Asset Example" style={styles.exampleImage} /> */}
            {/* <img src={internRoast} alt="Send Example" style={styles.overlappingImage} /> */}
          </div>
        </div>
      </div>

      <div style={styles.featuresSection}>
        {features.map((feature, index) => (
          <div 
            key={index} 
            style={{
              ...styles.featureRow,
              flexDirection: index % 2 === 0 ? 'row' : 'row-reverse'
            }}
          >
            <div style={{
              ...styles.featureContent,
              alignItems: index % 2 === 0 ? 'flex-end' : 'flex-start',
              textAlign: index % 2 === 0 ? 'right' : 'left'
            }}>
              <Typography variant="h3" style={styles.featureTitle}>
                {feature.title}
              </Typography>
              <Typography variant="body1" style={styles.featureDescription}>
                {feature.description}
              </Typography>
            </div>
            <div style={styles.featureImageContainer}>
              {feature.images?.length === 1 ? (
                <img 
                  src={feature.images[0]} 
                  alt={feature.title}
                  style={styles.featureImage}
                />
              ) : feature.images?.length === 2 ? (
                <div style={styles.imageStack}>
                  <img 
                    src={feature.images[0]} 
                    alt={`${feature.title} 1`}
                    style={styles.firstStackedImage}
                  />
                  <img 
                    src={feature.images[1]} 
                    alt={`${feature.title} 2`}
                    style={styles.secondStackedImage}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  product: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    boxSizing: 'border-box' as const,
    // paddingTop: '1rem',
  },
  heroSection: {
    display: 'flex',
    flexDirection: 'row' as const,
    width: '100%',
    height: '80vh',
    justifyContent: 'center',
  },
  leftColumn: {
    flex: '1 1 50%',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center' as const,
    maxWidth: '800px',
  },
  rightColumn: {
    // flex: '1 1 50%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  imageStack: {
    position: 'relative' as const,
    width: '100%',
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
  },
  exampleImage: {
    position: 'absolute' as const,
    width: 'auto',
    height: '100%',
    maxWidth: '100%',
    objectFit: 'contain' as const,
    // right: '50%',
    margin: "20px",
    borderRadius: '24px',
    border: '2px solid black',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
  },
  overlappingImage: {
    position: 'absolute' as const,
    width: 'auto',
    height: '100%',
    maxWidth: '100%',
    objectFit: 'contain' as const,
    zIndex: 1,
    left: '50%',
    margin: "20px",
    borderRadius: '24px',
    border: '2px solid black',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
  },
  heroTitle: {
    fontSize: 'clamp(2rem, 5vw, 3rem)',
    fontWeight: 'bold',
    marginBottom: 'clamp(1.5rem, 3vw, 3rem)',
  },
  heroSubtitle: {
    fontSize: 'clamp(1rem, 2vw, 1.2rem)',
    marginBottom: 'clamp(1rem, 2vw, 2rem)',
    lineHeight: '2',
    color: LightTheme.gray,
  },
  aiAssistant: {
    color: LightTheme.primaryAI,
  },
  featureList: {
    listStyle: 'none',
    padding: 0,
    marginBottom: '2rem',
  },
  ctaButtons: {
    display: 'flex',
    gap: 'clamp(0.5rem, 1vw, 1rem)',
    marginTop: 'clamp(1rem, 2vw, 2rem)'
  },
  appStoreLink: {
    display: 'inline-block',
    textDecoration: 'none',
  },
  appStoreBadge: {
    height: '50px',
    width: 'auto',
  },
  webPreviewButton: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0 24px',
    height: '50px',
    backgroundColor: LightTheme.primaryAI,
    color: 'white',
    textDecoration: 'none',
    borderRadius: '8px',
    fontSize: '1.1rem',
    fontWeight: '500',
  },
  orText: {
    color: LightTheme.gray,
    alignSelf: 'center',
    margin: '0 1rem',
    fontSize: "1.5rem"
  },
  featuresSection: {
    width: 'clamp(50%, 80%, 100%)',
    display: 'flex',
    flexDirection: 'column' as const,
    padding: 'clamp(2rem, 4vw, 4rem) 0',
  },
  featureRow: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    alignItems: 'center',
    // padding: '2rem',
  },
  featureContent: {
    flex: '1',
    padding: 'clamp(1rem, 2vw, 2rem)',
    display: 'flex',
    flexDirection: 'column' as const,
    gap: 'clamp(0.75rem, 1.5vw, 1.5rem)',
  },
  featureImageContainer: {
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 'clamp(1rem, 2vw, 2rem)',
    height: '100vh',
  },
  featureTitle: {
    fontSize: 'clamp(1.8rem, 4vw, 2.5rem)',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  featureDescription: {
    fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
    marginBottom: '1rem',
  },
  platformChips: {
    display: 'flex',
    gap: '0.5rem',
    marginTop: 'auto',
  },
  platformChip: {
    backgroundColor: LightTheme.primaryAI,
    color: 'white',
  },
  featureImage: {
    maxWidth: '100%',
    maxHeight: '90vh',
    height: 'auto',
    objectFit: 'contain' as const,
    borderRadius: '24px',
    border: '2px solid black',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
  },
  firstStackedImage: {
    position: 'absolute' as const,
    width: 'auto',
    height: '100%',
    borderRadius: '24px',
    border: '2px solid black',
    right: '0%',
    transform: 'translateX(50%)',
    margin: "20px",
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
  },
  secondStackedImage: {
    position: 'absolute' as const,
    width: 'auto',
    height: '100%',
    borderRadius: '24px',
    border: '2px solid black',
    left: '25%',
    transform: 'translateX(-50%)',
    zIndex: 1,
    margin: "20px",
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
  },
};

export default Product;