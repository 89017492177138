import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Message, MessageType } from '../types';
import {LightTheme} from '../styles/colors';

interface MessageProps {
  message: Message;
  onOptionClick?: (option: string) => void;
}

const MessageComponent: React.FC<MessageProps> = ({ message, onOptionClick }) => {
  const formattedTime = message.timestamp.toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });

  const renderMessageContent = () => {
    switch (message.type) {
      case MessageType.TEXT: {
        const optionsRegex = /<option>(.*?)<\/option>/g;
        const parts = message.text.split(optionsRegex);
        const options = message.text.match(optionsRegex)?.map(opt => 
          opt.replace(/<\/?option>/g, '')
        ) || [];

        return (
          <div>
            {parts[0].trim() && (
              <ReactMarkdown
                components={{
                  a: ({ node, ...props }) => (
                    <a target="_blank" rel="noopener noreferrer" {...props} style={styles.link} />
                  ),
                  img: ({ node, ...props }) => (
                    <img {...props} style={styles.image} />
                  ),
                }}
              >
                {parts[0]}
              </ReactMarkdown>
            )}
            
            {options.length > 0 && (
              <div style={styles.optionsContainer}>
                {options.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => onOptionClick?.(option)}
                    style={styles.optionButton}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
            
            {parts.length > 1 && parts[parts.length - 1].trim() && (
              <ReactMarkdown>
                {parts[parts.length - 1]}
              </ReactMarkdown>
            )}
          </div>
        );
      }
      case MessageType.QR_CODE:
        return (
          <img
            src={message.text}
            alt="QR Code"
            style={styles.qrCode}
          />
        );
      case MessageType.TOOL_USE:
        return <div style={styles.toolUse}>🛠️ {message.text}</div>;
      case MessageType.IMAGE:
        return (
          <img
            src={message.text}
            alt="Uploaded image"
            style={styles.uploadedImage}
          />
        );
      case MessageType.ERROR:
        return <div style={{ color: 'red' }}>{message.text}</div>;
      default:
        return <div>{message.text}</div>;
    }
  };

  return (
    <div style={{
      ...styles.message,
      ...(message.sender === 'user' ? styles.userMessage : styles.botMessage)
    }}>
      <div style={styles.messageContent}>
        {renderMessageContent()}
      </div>
      <div style={{
        ...styles.messageTime,
        ...(message.sender === 'user' ? styles.userMessageTime : styles.botMessageTime)
      }}>
        {formattedTime}
      </div>
    </div>
  );
};

const styles = {
  message: {
    marginBottom: '10px',
    fontSize: '14px',
    color: LightTheme.textColor,
  },
  userMessage: {
    alignSelf: 'flex-end',
  },
  botMessage: {
    alignSelf: 'flex-start',
  },
  messageContent: {
    padding: '0px 15px',
  },
  link: {
    color: LightTheme.primaryAI,
    textDecoration: 'underline',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
    margin: '5px 0',
  },
  qrCode: {
    width: '300px',
    height: '300px',
    marginTop: '10px',
  },
  messageTime: {
    fontSize: '12px',
    marginTop: '5px',
    opacity: 0.7,
  },
  userMessageTime: {
    textAlign: 'right' as const,
    paddingRight: '15px',
  },
  botMessageTime: {
    textAlign: 'left' as const,
    paddingLeft: '15px',
  },
  toolUse: {
    marginTop: '10px',
    fontStyle: 'italic',
    fontSize: '12px',
    opacity: 0.8,
  },
  uploadedImage: {
    maxWidth: '300px',
    maxHeight: '300px',
    borderRadius: '8px',
    objectFit: 'contain' as const,
    margin: '5px 0',
  },
  optionsContainer: {
    display: 'flex',
    // flexDirection: 'column' as const,
    width: 'auto',
    gap: '8px',
    margin: '10px 0',
  },
  optionButton: {
    padding: '10px 15px',
    width: 'auto',
    backgroundColor: LightTheme.primaryAI,
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background-color 0.3s',
    textAlign: 'left' as const,
    ':hover': {
      // backgroundColor: LightTheme.primaryAIHover,
    },
  },
};

export default React.memo(MessageComponent);